const englishTopics = [
    "passage",
    "Synonyms",
    "Sentence completion",
    "Antonyms",
    "Consonants",
    "Sentence interpretation",
    // "Vowels",
    "Speech production",
    "Stress and syllables",
    "Question tags",
    // "punctuation",
    "Aspect number",
    // "concord",
    "Homonyms",
    // "clause_and_sentence_patterns",
    // "Vowel",
    // "emphatic stress"
  ];
  // console.log(englishTopics);

  
export default englishTopics