const physicsTopics = [
  "MEASUREMENTS AND UNITS",
  "Scalars and Vectors",
  "Motion",
  "Gravitational field",
  "Equilibrium of Forces",
  "Friction",
  "Work, Energy and Power",
  "Friction (Stoke's Law)",
  "Elasticity",
  "Simple Machines",
  "Pressure",
  "Pressure - Determination of Density of Solids and Liquids",
  "Temperature and Its Measurement",
  "Thermal Expansion",
  "Gas Laws",
  "Quantity of Heat",
  "Change of State",
  "Van der Waal gas",
  "Structure of Matter and Kinetic Theory",
  "Vapours",
  "Change of State: Application in Appliances",
  "Vapours - Formation of Dew",
  "Heat Transfer",
  "Waves",
  "Propagation of Sound Waves",
  "Characteristics of Sound Waves",
  "Light Energy",
  "Characteristics of Sound",
  "Reflection of Light at Plane and Curved Surfaces",
  "Refraction of Light Through at Plane and Curved Surfaces",
  "Refraction of Light Through Plane and Curved Surfaces",
  "Dispersion of light and colours",
  "Optical Instruments",
  "Refraction of Light Through a Plane Surface",
  "Refraction of Light Through a Glass Prism",
  "Electrostatics",
  "Capacitors",
  "Electric Cells",
  "Current Electricity",
  "Electrical Energy and Power",
  "Current Elecricity",
  "Magnets and Magnetic Fields",
  "Force on a Current-Carrying Conductor in a Magnetic Field",
  "Quantitative Treatment of Force Between Two Parallel Current-Carrying Conductors",
  "Conversion of Galvanometers to Ammeters and Voltmeter Using Shunts and Multipliers",
  "Electromagnetic Induction",
  "Conversion of Galvanometers to Ammeters and Voltmeters Using Shunts and Multipliers",
  "Sensitivity of a Galvanometer",
  "Moving Coil and Moving Iron Instruments",
  "The D.C. Motor",
  "Electromagnets",
  "Carbon Microphone",
  "Force on a Current-Carrying Conductor in a Magnetic Field - The D.C. Motor",
  "Force on a Charge Moving in a Magnetic Field",
  "Simple A. C. Circuits",
  "Simple A.C. Circuits",
  "Conduction of Electricity Through",
  "Elementary Modern Physics",
  "Introductory Electronics"
];
// console.log(physicsTopics);

export default physicsTopics;
  