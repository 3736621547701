import React, { useState, useContext } from "react";
import instance from "../../Context/axiosConfig";
import "../../Css/createAuthorizationToken.css";
import { AuthContext } from "../../Context/AuthContext";
import { BiCopy } from "react-icons/bi";
import { BiCheck } from 'react-icons/bi';


const CreateAuthorizationToken = () => {
  const { config } = useContext(AuthContext);
  const [collectionId, setCollectionId] = useState("");
  const [adminPass, setAdminPass] = useState("");
  const [token, setToken] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [iscopied, setIscopied] = useState(false)

  const handleCreateToken = async () => {
    //{ collectionId, adminPass }
    try {
      setLoading(true);
      const response = await instance.post(
        "/audio/authorization-token",
        { collectionId, adminPass },
        config
      );
      setToken(response.data.token);
      setError("");
      setLoading(false);
    } catch (error) {
      console.log(error);
      setError(error.response.data.message || "An error occurred");
      setTimeout(() => {
        setError("");
      }, 4000);
      setToken("");
      setLoading(false);
    }
  };

  const handleCopyToken = () => {
    navigator.clipboard
      .writeText(token)
      .then(() => {
        setIscopied(true)
        setTimeout(() => {setIscopied(false)}, 4000)
      })
      .catch((error) => console.error("Unable to copy token: ", error));
  };

  return (
    <div
      style={{
        padding: "20px",
        width: "100%",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <div className="authorization-container">
        <h2>Create Authorization Token</h2>
        <div>
          <label htmlFor="collectionId">Collection ID:</label>
          <input
            type="text"
            id="collectionId"
            value={collectionId}
            onChange={(e) => setCollectionId(e.target.value)}
          />
        </div>
        <div>
          <label htmlFor="adminPass">Admin Password:</label>
          <input
            type="password"
            id="adminPass"
            value={adminPass}
            onChange={(e) => setAdminPass(e.target.value)}
          />
        </div>
        <button onClick={handleCreateToken}>Create Token</button>
        {token && (
          <div>
            <p>Token: {token}</p>
            <button onClick={handleCopyToken} disabled={!token || loading}>
              {!iscopied? <BiCopy />: <BiCheck />}
              &nbsp; {!iscopied? 'Copy' : 'Copied'}
            </button>
          </div>
        )}
        {error && <p style={{ color: "red" }}>{error}</p>}
        {loading && <p style={{ color: "blue" }}>loading... </p>}
      </div>
    </div>
  );
};

export default CreateAuthorizationToken;
