const mathematicsTopics = [
  "Fractions, Decimals, Approximations and Percentages",
  "Number bases",
  "Indices, Logarithms and Surds",
  "Fractions, Decimals, Approximations, and Percentages",
  "Sets",
  "Change of bases in logarithm and application",
  "Change of Bases in Logarithm",
  "Application of Logarithm",
  "Polynomials",
  "Change of Subject of Formula",
  "Application of logarithms",
  "Binary Operations",
  "Matrices and Determinants",
  "Euclidean Geometry",
  "Progression",
  "Inequalities",
  "Mensuration",
  "Coordinate Geometry",
  "Euclidean Geometry - Construction",
  "Loci",
  "Trigonometry",
  "Differentiation",
  "Application of differentiation",
  "Integration",
  "Representation of data",
  "Measures of Location",
  "Measures of Dispersion",
  "Permutation and Combination",
  "Probability"
];
// console.log(mathematicsTopics);



export default mathematicsTopics;
