// AudioCollectionDetails.js
import React, { useState, useEffect, useContext, useCallback } from "react";
import { useParams } from "react-router-dom";
import instance from "../../Context/axiosConfig";
import { AuthContext } from "../../Context/AuthContext";
import uuid from "react-uuid"; // Importing uuid package
import "../../Css/audioDetails.css";
import Loader from "../GeneralScreens/Loader";
import CustomAudioPlayer from "./audioWrapper";

const AudioCollectionDetails = () => {
  const { audioCollectionId, audioCollectionTitle } = useParams();
  const { config } = useContext(AuthContext);
  const [deviceInfo, setDeviceInfo] = useState(localStorage.getItem("deviceInfo"));
  const [audios, setAudios] = useState([]);
  const [token, setToken] = useState("");
  const [isAuthorized, setIsAuthorized] = useState(true);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [currentAudioIndex, setCurrentAudioIndex] = useState(0);

  const fetchAudioCollection = useCallback(async () => {
    try {
      setLoading(true);
      const response = await instance.get(`/audio/audiosById?collectionId=${audioCollectionId}&deviceInfo=${deviceInfo}`, config);
      setAudios(response.data);
      setLoading(false);
      setSuccess("Audio has been fetched successfully✔✔");
      setTimeout(() => {
        setSuccess("");
      }, 4000);
      setIsAuthorized(true); // Assume the user is authorized initially
    } catch (error) {
      console.error("Error fetching audio collection:", error);
      setError(error.response.data.message);
      setTimeout(() => {
        setError("");
      }, 4000);
      setIsAuthorized(false);
      setLoading(false);
      if (error.response.data.statusCode === 500) {
        setIsAuthorized(true);
        setError("something went wrong, please refresh the page");
      }
    }
  }, [audioCollectionId, config, deviceInfo]);

  useEffect(() => {
    let storedDeviceInfo = localStorage.getItem("deviceInfo");
    if (!storedDeviceInfo) {
      storedDeviceInfo = uuid();
      localStorage.setItem("deviceInfo", storedDeviceInfo);
    }
    setDeviceInfo(storedDeviceInfo);

    fetchAudioCollection();
  }, [audioCollectionId, fetchAudioCollection]);
// Inside your useEffect hook where you handle the audio playback
// Inside AudioCollectionDetails.js

useEffect(() => {
  // Check if all audios have been played
  if (currentAudioIndex >= audios?.length) {
    setCurrentAudioIndex(0);
  }

  // Get the current audio
  const currentAudio = audios[currentAudioIndex];
  const audioPlayer = document.getElementById(`audio-${currentAudio?._id}`);
  console.log(audioPlayer);
  const otherAudios = audios.filter((audio, index) => index !== currentAudioIndex);
  otherAudios.forEach((audio) => {
    const audioPlayer = document.getElementById(`audio-${audio?._id}`);
    if(audioPlayer){
      audioPlayer.pause()

    }
  })
  
  // // Play the current audio
  // if (audioPlayer) {
  //   audioPlayer.play();
  // }

  }, [audios, currentAudioIndex]);
  const updateCurrentAudioIndex = (index) => {
    setCurrentAudioIndex(index);
  };
  const handleEnded = () => {
    setCurrentAudioIndex(prevIndex => prevIndex + 1);
    const audioPlayer = document.getElementById(`audio-${audios[currentAudioIndex + 1]?._id}`)
    console.log(audioPlayer);
    if(audioPlayer){
      audioPlayer.play()
    }
  };



  const handleAuthorize = async () => {
    try {
      setLoading(true);
      await instance.post("/audio/authorize", { token, deviceInfo, collectionId: audioCollectionId }, config);
      setLoading(false);
      setIsAuthorized(true);
      setSuccess("collection has been unlocked successfully✔✔");
      setTimeout(() => {
        setSuccess("");
      }, 4000);
      fetchAudioCollection();
    } catch (error) {
      setLoading(false);
      setError(error.response.data.message);
      setTimeout(() => {
        setError("");
      }, 4000);
      console.log(error);
    }
  };
  function transformDropboxUrl(dropboxUrl) {
    // Check if the URL contains "www.dropbox.com"
    if (dropboxUrl.includes("www.dropbox.com")) {
        // Extract the unique identifier from the URL
        const identifier = dropboxUrl.split("/").slice(-3).join("/");
        // Construct the new URL with the desired base URL
        return `https://dl.dropboxusercontent.com/scl/fi/${identifier}`;
    }
    // If the URL does not contain "www.dropbox.com", return it unchanged
    return dropboxUrl;
}

  return (
    <div className="audio-collection-details">
      <h1 className="caption">{audioCollectionTitle}</h1>
      {!isAuthorized && (
        <div className="authorization-form">
          <label htmlFor="tokenInput">Enter Token:</label>
          <input
            type="text"
            id="tokenInput"
            value={token}
            onChange={(e) => setToken(e.target.value)}
          />
          <button onClick={handleAuthorize}>Authorize</button>
        </div>
      )}
      {loading && <Loader />}
      {error && <p style={{ color: "red" }}>{error}</p>}
      {success && <p style={{ color: "green" }}>{success}</p>}
      <div className="audio-list">
        {isAuthorized &&
          audios?.map((audio, index) => (
            <div key={audio?._id} className="audio-item">
              <span style={{ alignSelf: "flex-start" }}>{audio.title}</span>
              <CustomAudioPlayer
                src={transformDropboxUrl(audio.audioUrl)}
                controls
                className="audio-player"
                controlsList="nodownload"
                id={`audio-${audio?._id}`} // Add key prop for re-rendering
                // autoPlay= {currentAudioIndex == 0}
                currentAudioIndex={currentAudioIndex} // Pass currentAudioIndex as a prop
                onEnded={handleEnded}
                index={index} // Pass the index to the CustomAudioPlayer
                updateCurrentAudioIndex={updateCurrentAudioIndex}
              />
            </div>
          ))}
      </div>
    </div>
  );
};

export default AudioCollectionDetails;
