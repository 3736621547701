const chemistryTopics = [
  "Atomic Structure",
  "Equilibrium",
  "Nuclear Chemistry",
  "Structure of the Atom",
  "Atomic Structure and Subatomic Particles",
  "Chemical Bonding",
  "Ionic and Covalent Bonding",
  "Protons, Neutrons, Electrons",
  "Subtopic: Protons, Neutrons, Electrons",
  "Electronic Configuration and Periodic Table",
  "Metallic Bonding",
  "Structure of the Atom - Electronic configuration and periodic table",
  "Periodic Table",
  "Electronic Configuration",
  "Structure of the Atom and Electronic Configuration",
  "Properties of metals, metallic lattice",
  "Periods, groups, valence electrons",
  "Groups",
  "Periods",
  "Valence Electrons",
  "Properties of Metals",
  "Metallic Lattice",
  "Structure of the Atom - Periods, Groups, Valence Electrons",
  "Chemical Bonding: Properties of metals, metallic lattice",
  "Structure of the Atom -> Periods, groups, valence electrons",
  "Structure of the Atom and subtopic Periods, groups, valence electrons",
  "States of Matter",
  "Chemical Reactions",
  "Kinetic Theory",
  "Intermolecular Forces",
  "Mole Concept",
  "Acids, Bases, and Salts",
  "Acids, Bases, and Salts: Properties of acids and bases",
  "Acids, Bases, and Salts - Properties of acids and bases",
  "Chemical Kinetics",
  "Chemical Equilibrium",
  "Thermodynamics",
  "Thermodynamics - Enthalpy, entropy, and Gibbs free energy",
  "Electrochemistry",
  "Galvanic cells",
  "Nernst equation",
  "Electrolytic cells",
  "Inorganic Chemistry",
  "Inorganic Chemistry - Periodic table and periodicity",
  "Inorganic Chemistry / Transition metals and their compounds",
  "Environmental Chemistry",
  "Environmental Chemistry - Water Pollution",
  "Environmental Chemistry - Soil Pollution",
  "Environmental Chemistry - Air Pollution",
  "Environmental Chemistry - Sustainable chemistry practices",
  "Chemistry of Non-Metals",
  "Metallurgy",
  "Metallurgy - Extraction methods",
  "Metallurgy - Refining processes",
  "Chemistry in Industry",
  "Chemistry in Industry - Polymerization",
  "Chemistry in Industry - Haber process",
  "Chemistry in Industry - Contact process",
  "Chemistry in Industry - Industrial processes",
  "Organic chemistry",
  "Organic Chemistry - Alkanals and Alkanones",
  "Organic Chemistry - Alkanols Primary, Secondary, Tertiary"
];
// console.log(chemistryTopics);

  export default chemistryTopics