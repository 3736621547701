// CustomAudioPlayer.js
import React, { Component } from "react";
import PropTypes from "prop-types";
import "../../Css/customAudioPlayer.css"; // Import CSS for custom styling
import {
  FaTachometerAlt,
  FaFastForward,
  FaPlay,
  FaPause,
  FaBackward,
} from "react-icons/fa";

class CustomAudioPlayer extends Component {
  static propTypes;
  static defaultProps;

  constructor(props) {
    super(props);
    this.audioEl = React.createRef();
    this.state = {
      currentTime: 0,
      duration: 0,
      isPlaying: false,
      showSpeedControl: false,
      playbackSpeed: 1.0,
    };
  }
  pauseAudio = () => {
    const audio = this.audioEl.current;
    if (audio) {
      this.setState({ isPlaying: false });
    }
  };

  handleAudioEnded = () => {
    this.setState({ isPlaying: false, currentTime: 0 }); // Reset isPlaying and currentTime
  };
  handlePlaying = () => {
    this.setState({ isPlaying: true });
  };
  componentDidMount() {
    const audio = this.audioEl.current;

    if (!audio) return;

    this.updateVolume(this.props.volume);

    audio.addEventListener("timeupdate", this.onTimeUpdate);
    audio.addEventListener("loadedmetadata", this.onLoadedMetadata);
    audio.addEventListener("ended", this.handleAudioEnded); // Add event listener for 'ended'
    audio.addEventListener("playing", this.handlePlaying); // Add event listener for 'playing'
  }

  componentWillUnmount() {
    const audio = this.audioEl.current;

    if (!audio) return;

    audio.removeEventListener("timeupdate", this.onTimeUpdate);
    audio.removeEventListener("loadedmetadata", this.onLoadedMetadata);
  }

  onLoadedMetadata = () => {
    const audio = this.audioEl.current;
    if (audio) {
      this.setState({ duration: audio.duration });
    }
  };

  onTimeUpdate = () => {
    const audio = this.audioEl.current;
    if (audio) {
      this.setState({ currentTime: audio.currentTime });
    }
  };

  handlePlayPause = () => {
    const audio = this.audioEl.current;
    const { index, updateCurrentAudioIndex } = this.props;
    updateCurrentAudioIndex(index);
    if (audio) {
      // if (audio.readyState >= 2) {
        if (audio.paused) {
          audio.play();
          this.setState({ isPlaying: true });
        } else {
          audio.pause();
          this.setState({ isPlaying: false });
        }
      // } else {
      //   // If audio is not ready, wait for it to be ready before playing
      //   audio.addEventListener("canplaythrough", () => {
      //     audio.play();
      //     this.setState({ isPlaying: true });
      //     audio.removeEventListener("canplaythrough", null); // Remove the event listener to avoid memory leaks
      //   });
      // }
    }
  };
  componentDidUpdate(prevProps) {
    // if (this.props.autoPlay !== prevProps.autoPlay && this.props.autoPlay) {
    //   // const speed = localStorage.getItem('playBack') 
    //   // this.setState({ playbackSpeed: speed, showSpeedControl: false });
    //   //   const audio = this.audioEl.current;
    //   //   if (audio) {
    //   //   audio.playbackRate = speed;
    //   //   }
    // }
    if (this.props.currentAudioIndex !== prevProps.currentAudioIndex) {
        // Check if the currentAudioIndex prop has changed
        this.pauseAudio();
        // const speed = localStorage.getItem('playBack') 
        // this.setState({ playbackSpeed: speed, showSpeedControl: false });
        // const audio = this.audioEl.current;
        // if (audio) {
        // audio.playbackRate = speed;
        // }
    }
  }

  handleSeek = (seekTime) => {
    const audio = this.audioEl.current;
    if (audio) {
      const newTime = audio.currentTime + seekTime;
      audio.currentTime = Math.max(0, Math.min(audio.duration, newTime));
      this.setState({ currentTime: audio.currentTime });
    }
  };

  toggleSpeedControl = () => {
    this.setState((prevState) => ({
      showSpeedControl: !prevState.showSpeedControl,
    }));
  };

  handlePlaybackSpeedChange = (speed) => {
    this.setState({ playbackSpeed: speed, showSpeedControl: false });
    localStorage.setItem('playBack', speed)
    const audio = this.audioEl.current;
    if (audio) {
      audio.playbackRate = speed;
    }
  };

  updateVolume(volume) {
    const audio = this.audioEl.current;
    if (
      audio !== null &&
      typeof volume === "number" &&
      volume !== audio?.volume
    ) {
      audio.volume = volume;
    }
  }

  render() {
    const {
      currentTime,
      duration,
      isPlaying,
      showSpeedControl,
      playbackSpeed,
    } = this.state;

    return (
      <div className="custom-audio-player">
        <div className="seek-bar-container">
          <input
            style={{ width: "100%" }}
            type="range"
            min={0}
            max={duration || 0}
            value={currentTime}
            onChange={(e) =>
              this.handleSeek(parseFloat(e.target.value) - currentTime)
            }
            className="seek-bar"
          />
        </div>
        <div className="controls">
          <audio
            ref={this.audioEl}
            src={this.props.src}
            autoPlay={
              this.audioEl?.current?.readyState >= 2 && this?.props?.autoPlay
            }
            preload={this.props.preload}
            loop={this.props.loop}
            controls={false} // Hide default controls
            onEnded={this.props.onEnded}
            id={this.props.id}
          />
          <span className="time">
            {`${String(Math.floor(currentTime / 60)).padStart(2, "0")}:${String(
              Math.floor(currentTime) % 60
            ).padStart(2, "0")}`}
          </span>
          <button
            className="control-button"
            onClick={() => this.handleSeek(-10)}
          >
            <FaBackward size={20} />
          </button>
          <button className="control-button" onClick={this.handlePlayPause}>
            {isPlaying ? <FaPause size={20} /> : <FaPlay size={20} />}
          </button>
          <button
            className="control-button"
            onClick={() => this.handleSeek(10)}
          >
            <FaFastForward size={20} />
          </button>
          <div className="speed-control-container">
            <FaTachometerAlt
              className="control-button"
              onClick={this.toggleSpeedControl}
              style={{ fontSize: "30px" }}
            />
            {showSpeedControl && (
              <div className="speed-control-card">
                <div className="speed-control">
                  {[0.25, 0.5, 0.75, 1.0, 1.25, 1.5, 1.75, 2.0].map((speed) => (
                    <button
                      key={speed}
                      className={`speed-button ${
                        playbackSpeed === speed ? "active" : ""
                      }`}
                      onClick={() => this.handlePlaybackSpeedChange(speed)}
                    >
                      {speed}x
                    </button>
                  ))}
                </div>
              </div>
            )}
          </div>

          <span className="time">
            {`${String(Math.floor(duration / 60)).padStart(2, "0")}:${String(
              Math.floor(duration) % 60
            ).padStart(2, "0")}`}
          </span>
        </div>
      </div>
    );
  }
}

CustomAudioPlayer.defaultProps = {
  autoPlay: false,
  loop: false,
  preload: "metadata",
  volume: 1.0,
};

CustomAudioPlayer.propTypes = {
  src: PropTypes.string.isRequired,
  autoPlay: PropTypes.bool,
  loop: PropTypes.bool,
  preload: PropTypes.oneOf(["", "none", "metadata", "auto"]),
  volume: PropTypes.number,
  onended: PropTypes.func,
  currentAudioIndex: PropTypes.number,
};

export default CustomAudioPlayer;
