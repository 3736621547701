const crsTopics = [
    "Miracles",
    "The Parables",
    "Faith, Courage, and Protection",
    "God's Message to Nineveh",
    "Social Justice, True Religion, and Divine Love",
    "Holiness and Divine call",
    "Religious Reforms in Judah",
    "Concern for Judah",
    "The Trials and the Death of Jesus",
    "Resurrection, Appearances, and Ascension of Jesus",
    "Greed and Its Effects",
    "The Supremacy of God",
    "Leadership Qualities",
    "Divine Providence, Guidance, and Protection",
    "A Man After God's Own Heart",
    "Decision-Making",
    "The Sovereignty of God",
    "The Covenant",
    "Divine Providence, Guidance, and Protection: Guidance and Protection",
    "Divine Providence, Guidance, and Protection: Provision"
  ];
  // console.log(crsTopics);


  export default crsTopics  